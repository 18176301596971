/* .overlay-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: url('https://static.wixstatic.com/media/9c608a_84dd07f87f0848c4b78f0f134135029a~mv2_d_4272_2848_s_4_2.jpg') center/cover no-repeat;
  }
  
  .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 21, 124, 0.9);
  } */

  .overlay-container {
    position: relative;
    width: 100vw;
    min-height: 100vh; /* Set min-height to ensure the container covers the content height */
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://static.wixstatic.com/media/9c608a_84dd07f87f0848c4b78f0f134135029a~mv2_d_4272_2848_s_4_2.jpg') center/cover no-repeat;
    z-index: -1; /* Place the background image behind the content */
  }
  
  .overlay-content {
    padding: 50px; /* Adjust the padding as needed to create the desired overlay effect */
    background-color: rgba(24, 21, 124, 0.9);
    color: white; /* Change text color to white for better visibility on the overlay */
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }

  .glob-centre-3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.text-row-mn{
    font-size: 32px;
    color: rgb(234,222,113);
    font-weight: 500;
}

.text-para-mn{
    font-size: 20px;
    color: white;
    font-weight: 300;
    line-height: 1.7em;
}

.carousel .slide {
    height: 50% !important;
}

.container2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
  }

  .image2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .button {
    width: 70%;
    height: 50px;
    background-color: #cf2e2e;
    color: #ffffff;
    /* font-family: 'roboto'; */
    font-size: 18px;
    border: 0px;
    border-radius: 15px;
    font-weight: 800;
    margin-top: 20px;
    cursor: pointer;
  }


  @media screen and (max-width: 767px) {
    .container {
      flex-direction: column;
    }

    .container2 {
        flex-direction: column;
      }

    .content,
    .image {
      flex: none;
      width: 100%;
    }
  }

  
