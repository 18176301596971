.overlay-container {
    position: relative;
    width: 100vw;
    min-height: 100vh; /* Set min-height to ensure the container covers the content height */
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('https://static.wixstatic.com/media/9c608a_84dd07f87f0848c4b78f0f134135029a~mv2_d_4272_2848_s_4_2.jpg') center/cover no-repeat;
    z-index: -1; /* Place the background image behind the content */
  }
  
  .overlay-content {
    padding: 50px; /* Adjust the padding as needed to create the desired overlay effect */
    background-color: rgba(24, 21, 124, 0.9);
    color: white; /* Change text color to white for better visibility on the overlay */
  }

  .form-container {
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Display the form from the start on mobile devices */
  @media (max-width: 600px) {
    .form-container {
      margin: 0;
    }
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    font-weight: 400;
    margin-bottom: 5px;
    color: rgb(234,222,113);
    font-size: 18x;
  }
  
  .form-input {
    width: 100%;
    /* height: 20px; */
    padding: 10px;
    border: 2px solid white;
    border-radius: 4px;
    background-color: transparent;
    color: white;
    font-size: 18px;
  }

  .form-input-2 {
    width: 100%;
    height: 45px;
    padding: 10px;
    border: 2px solid white;
    border-radius: 4px;
    background-color: transparent;
    color: white;
    font-size: 18px;
  }

  .btn{
    height: fit-content;
    width: 150px;
    padding: 10px;
    border: 0px;
    background-color: rgb(234,222,113);;
    color: black;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 3%;
    cursor: pointer;
  }