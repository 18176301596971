.big-screen-text {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    color: 'white';
    font-size: '76px';
    font-weight: 'bold';
    text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
}

.small-screen-text {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    color: 'white';
    font-size: '24px';
    font-weight: 'bold';
    text-shadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
}

@keyframes revealAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .reveal-text {
    overflow: hidden;
    white-space: nowrap;
    animation: revealAnimation 5s linear; /* Adjust the duration (5s) as per your preference */
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .image {
    flex: 1;
    text-align: center;
    padding: 20px;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }

  .image-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    /* gap: 10px; */
  }
  
  .image-wrapper {
    position: relative;
    width: 100%;
    height: 310px;
    overflow: hidden;
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  .text-cont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  
  /* Media Query for Mobile */
  @media screen and (max-width: 767px) {
    .container {
      flex-direction: column;
    }
    .content,
    .image {
      flex: none;
      width: 100%;
    }
  }
  
  